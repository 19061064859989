import React, {lazy, Suspense} from 'react';
import {Route, Routes} from "react-router-dom";

import Licences from "./installation/licences"
import LinearIndeterminate from "../components/layout-component/linear-progress-bar";
import ErrorBoundaryWrapper from "../components/error-boundary/error-boundary"
import InstallationApi from "../../apis/installation/installation";

const Drivers=React.lazy(()=> import("./installation/driver-setting/index"))
const ChargePoint=lazy(()=> import("./installation/chargepoints"))
const Invoice=lazy(()=> import("./invoices"))
const InstallationGenreralInfo=lazy(()=> import("./installation/general-info"))
const Installation=lazy(()=> import("./installation/new-installation"))
const TeamMembers=lazy(()=>import("./team-members/index"))
const ManagedCompanies=lazy(()=>import("./managed-companies"))
const About=lazy(()=>import("./about"))
const Controllers=lazy(()=>import("./installation/controllers"))
const BillingSetting=lazy(()=>import("./installation/billing-setting"))
const Alerts=lazy(()=>import("./alerts"))
const ActivityLog=lazy(()=>import("./activity-log"))


function Index(props) {

    return (
        <>


            <Suspense fallback={<LinearIndeterminate/>}>
                <Routes>
                    <Route path={"/about-companies"} element={<About/>}/>
                    <Route path={"/managed-companies"} element={<ManagedCompanies/>}/>
                    <Route path={"/team-members"} element={<TeamMembers/>}/>
                    <Route path={"/alerts"} element={<Alerts/>}/>
                    <Route path={"/activity-log"} element={<ActivityLog/>}/>
                    <Route path={"/installation"} element={<Installation/>}/>
                    <Route path={"installation/transactions"} element={<Invoice/>}/>
                    <Route path={"installation/general-info"} element={<ErrorBoundaryWrapper><InstallationGenreralInfo/></ErrorBoundaryWrapper>}/>
                    <Route path={"installation/chargepoints"} element={<ChargePoint />}/>
                    <Route path={"installation/licences"} element={<Licences/>}/>
                    <Route path={"installation/drivers"} element={<Drivers/>}/>
                    <Route path={"installation/controllers"} element={<Controllers/>}/>
                    <Route path={"/installation/billing-settings"} element={<BillingSetting/>}/>
                </Routes>
                </Suspense >

                {/*</Box>*/}





        </>
    );
}

export default Index;