import React from 'react';
import {useSelector} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom"
import Registration from "./routes/authorization/registration"
import ConfigPage from "./routes/guest/config/index"
import Login from "./routes/authorization/login"
import 'react-toastify/dist/ReactToastify.css';
import MuiXLicense from "./routes/components/layout-component/mui-licence";
import PrivateRoutes from "./routes/components/authorization-component/private-routes";
import ForgotPassword from "./routes/authorization/forgot-password";
import {ToastContainer} from "react-toastify";


function MainApp(props) {
    const authUser = useSelector((state) => (state.authUser))
    // const [isLogin, setLogin] = useState(false)
    // const navigate = useNavigate()
    // const location = useLocation()
    // useEffect(() => {
    //     if(!location.pathname.startsWith("/registration") && !location.pathname.startsWith("/forget-password")){
    //         if (authUser.refreshToken === null ) {
    //             setLogin(false)
    //             return navigate("/login")
    //         }else{
    //                 setLogin(true)
    //                 navigate("/app/about-companies")
    //         }
    //     }
    //     // navigate("/app/installation")
    // }, [authUser.refreshToken])
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <MuiXLicense />
            <Routes>
                <Route path={"/registration/*"} element={<Registration/>}/>
                <Route path={"/config/*"} element={<ConfigPage/>}/>
                <Route path={"/forgot-password/*"} element={<ForgotPassword/>}/>
                <Route path={"/login"} element={authUser.isAuthenticated?<Navigate to={"/app/about-companies"}/>:<Login/>}/>
                {/*<Route path={"/forget-password"} element={<Registration/>}/>*/}
                {/*{isLogin && <Route path={"/app/*"} element={<SecurePath authUser={authUser}/>}/>}*/}
                <Route path={"/*"} element={<PrivateRoutes/>} />
            </Routes>
        </>
    );
}


export default MainApp;